import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearStoreObjectsError,
  fetchDynamicFilterValues,
  fetchFacilities,
  fetchStoreObjects
} from '~/App/shared/actions/storeObjectActions';
import {
  filteredEntitiesSelector,
  isFetchingMoreErrorSelector,
  isFetchingMoreSelector,
  remainingSelector,
  storeObjectsIsLoading
} from '~/App/shared/selectors/storeObjectsSelectors';
import { useTranslation } from '~/Locale';
import { deserializationFilters } from '~/helpers/storeObjects';
import { isMainCategory } from '~/helpers/filterSearchParams';
import { FILTER_PAGE_STEP_SIZE } from '~/config/constants';
import { getInitialAmountOfSkeletons } from '../helpers';
import { ObjectListDevice } from '../components/ObjectListContainer';

export const useFilteredObjects = (
  searchQuery = '',
  skipInitialFetch = false,
  device: ObjectListDevice
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isFetching = useSelector(storeObjectsIsLoading);
  const entities = useSelector(filteredEntitiesSelector(searchQuery));
  const remaining = useSelector(remainingSelector(searchQuery));
  const isFetchingMore = useSelector(isFetchingMoreSelector(searchQuery));
  const isFetchingMoreError = useSelector(
    isFetchingMoreErrorSelector(searchQuery)
  );
  const shouldFetch = useRef<boolean>(!skipInitialFetch);
  const hasFetchedInitial = useRef<boolean>(skipInitialFetch);

  // when this updates new data will be fetched
  const filters = useMemo(() => {
    return deserializationFilters(searchQuery ?? '');
  }, [searchQuery]);

  const objectList = useMemo(() => {
    const amountOfSkeletons = isFetchingMore
      ? Math.min(remaining, FILTER_PAGE_STEP_SIZE)
      : getInitialAmountOfSkeletons(device);
    const loaders = Array(amountOfSkeletons).fill({
      isLoader: true
    });

    return isFetching || isFetchingMore ? [...entities, ...loaders] : entities;
  }, [entities, isFetching, isFetchingMore, remaining, device]);

  // TODO: Get a real solution, this is just to keep search updated for now
  const infinityScrollTrigger = useCallback(
    async (inView: boolean) => {
      const nextOffset = objectList.length;

      const shouldFetchMore =
        inView && remaining > 0 && !isFetchingMore && !isFetchingMoreError;
      if (shouldFetchMore) {
        await dispatch(
          fetchStoreObjects({
            filters,
            offset: nextOffset
          })
        );
      }

      const shouldCleraError = isFetchingMoreError && !inView;
      if (shouldCleraError) {
        dispatch(clearStoreObjectsError());
      }
    },
    [
      objectList.length,
      remaining,
      isFetchingMore,
      dispatch,
      filters,
      isFetchingMoreError
    ]
  );

  useEffect(() => {
    const abortController = new AbortController();

    // TODO: [KVDBIL-1635] Cant use this server side doe to translationFunction dependency
    if (/*shouldFetch.current &&*/ isMainCategory(filters.vehicleType)) {
      dispatch(
        fetchDynamicFilterValues(filters.vehicleType, t, abortController.signal)
      );
    }

    return () => {
      abortController.abort();
    };
  }, [dispatch, filters.vehicleType, t]);

  // only need fetch facilities once
  useEffect(() => {
    const abortController = new AbortController();

    if (hasFetchedInitial.current) {
      dispatch(fetchFacilities(abortController.signal));
    }

    return () => {
      abortController.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();

    if (shouldFetch.current) {
      dispatch(
        fetchStoreObjects({
          filters,
          signal: abortController.signal
        })
      );
    }

    shouldFetch.current = true;

    return () => {
      abortController.abort();
    };
  }, [dispatch, filters]);

  return {
    infinityScrollTrigger,
    objectList,
    isFetchingMoreError
  };
};
