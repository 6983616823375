import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PromoSettingCMSData } from '~/App/shared/interfaces/PromoSettingCMSData';
import {
  filteredEntitiesSelector,
  hitsSelector,
  storeObjectsIsLoading
} from '~/App/shared/selectors/storeObjectsSelectors';
import { useTranslation } from '~/Locale';
import { CardList } from './CardList';
import styled from 'styled-components';
import { BodyText, Caption, Spacer, mq } from '@kvdbil/components';
import { isMainCategory } from '~/helpers/filterSearchParams';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { useIsCarguideFinished } from '../../Carguide/hooks';
import { useIsDevice } from '~/App/shared/hooks/useIsDevice';
import { BodyTextSkeleton } from '../../Blog/components/BlogSkeletons';
import { useFilteredObjects } from '../hooks/useFilteredObjects';

const HitsWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${mq('tablet')} {
    flex-direction: row;
    align-items: flex-start;
  }

  > * {
    flex: 1;
  }
`;

const HitsInfoText = styled.div`
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const FetchMoreError = styled(Caption)`
  color: ${({ theme }) => theme.colors.error.main};
`;

export type ObjectListDevice = 'mobile' | 'tablet' | 'desktop';

interface ObjectListContainerProps {
  promoSettingsData?: PromoSettingCMSData;
  searchQuery?: string;
  skipInitialFetch?: boolean;
  mainCategory?: MainCategory;
}

export const ObjectListContainer = ({
  promoSettingsData,
  searchQuery = '',
  skipInitialFetch = false,
  mainCategory
}: ObjectListContainerProps) => {
  const { t } = useTranslation();
  const isFetching = useSelector(storeObjectsIsLoading);
  const entities = useSelector(filteredEntitiesSelector(searchQuery));
  const hits = useSelector(hitsSelector(searchQuery));
  const { isCarguideFinished } = useIsCarguideFinished();

  const hasCertifiedObjects = useMemo(
    () => entities.some(({ processObject }) => processObject?.certified),
    [entities]
  );

  const filteredPromoData = useMemo(() => {
    if (isMainCategory(mainCategory)) {
      return {
        usageType: promoSettingsData?.usageType ?? null,
        promoCards:
          promoSettingsData?.promoCards?.filter(promoCard =>
            promoCard.applyForCategory.includes(mainCategory)
          ) ?? []
      };
    }
  }, [promoSettingsData, mainCategory]);

  const { isSmallerThan, isGreaterThan } = useIsDevice();
  const device = useMemo((): ObjectListDevice => {
    if (isSmallerThan('tablet')) {
      return 'mobile';
    }
    if (isGreaterThan('tablet') && isSmallerThan('laptop')) {
      return 'tablet';
    }
    return 'desktop';
  }, [isSmallerThan, isGreaterThan]);

  const { infinityScrollTrigger, objectList, isFetchingMoreError } =
    useFilteredObjects(searchQuery, skipInitialFetch, device);

  return (
    <>
      <CardList
        auctions={objectList}
        options={{
          isCertifiedAuctionsAvailable: hasCertifiedObjects,
          showSecondaryCardTemplate: Boolean(isCarguideFinished)
        }}
        promoSettingsData={filteredPromoData}
        hits={hits}
        searchQuery={searchQuery}
        infinityScrollTrigger={infinityScrollTrigger}
        device={device}
      />

      <HitsWrapper>
        <Spacer />
        <HitsInfoText>
          {isFetching ? (
            <BodyTextSkeleton style={{ width: '150px' }} />
          ) : (
            <>
              {isFetchingMoreError && (
                <FetchMoreError as="span">
                  {t('Couldn’t load more items. Please try again later.')}
                </FetchMoreError>
              )}
              <BodyText as="span">
                {t('Showing %1$d of %2$d hits', objectList.length, hits ?? 0)}
              </BodyText>
            </>
          )}
        </HitsInfoText>
        <Spacer />
      </HitsWrapper>
    </>
  );
};

export default ObjectListContainer;
